import {message} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { Context } from "../../.."
import useWindowDimensions from "../../../core/hooks/useWindowDimensions"
import $ from 'jquery'

import styles from "./XPageModal.module.scss"

interface XPageModalProps {
  id: string
  visible: boolean
  title: any
  content: any
  closable?: boolean
  nstc?: boolean
  activeZone?: boolean
  onMouseClose?: boolean
  onClose: () => void
  onDone?: () => void
  theme?: 'light' | 'dark'
}

const XPageModal: FC<XPageModalProps> = ({
  id, visible, title, content, onMouseClose = true, nstc = false, closable = true, activeZone = true, onClose, onDone, theme
}) => {

  const { loyaltyStore } = useContext(Context)

  const { width } = useWindowDimensions()

  const [isDataLoading, setIsDataLoading] = useState(false)

  const [mainVisible, setMainVisible] = useState(false)
  const [preVisible, setPreVisible] = useState(false)
  const [postVisible, setPostVisible] = useState(false)

  const [actZoneVisible, setActZoneVisible] = useState(false)
  const [actZoneShown, setActZoneShown] = useState(false)

  const [selectedStep, setSelectedStep] = useState(0)

  const open = () => {
    setPostVisible(true)
    setTimeout(() => {
      setPreVisible(true)
      setTimeout(() => {
        setMainVisible(true)
      }, 50)
    }, 50)
  }

  const close = () => {
    $(`#bw_page_${id}`).scrollTop(0)

    let page = document.getElementById(`bw_page_${id}`)
    if (page != null) {
      page.scrollIntoView()
    }

    setMainVisible(false)
    setTimeout(() => {
      setPreVisible(false)
      setTimeout(() => {
        setPostVisible(false)
      }, 300)
    }, 300)

    onClose()

    document.removeEventListener('mouseup', onMouseUp)
    document.removeEventListener('keydown', onKeydown)
  }

  const onMouseUp = (e: any) => {
    if (!onMouseClose) {
      return
    }

    let booking_window = document.getElementById(`booking_window_${id}`)

    let go1 = false

    if (booking_window != null && !booking_window.contains(e.target) && visible) {
      go1 = true
    }

    if (go1) {
      close()
    }
  }

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case 'Escape':
        close()
        break
    }
  }

  const onScroll = (ev: any) => {
    let { clientHeight, scrollHeight, scrollTop } = ev.target

    const head = document.getElementById(`bw_head_${id}`)
    if (head != null) {
      if (scrollTop > 10 && head.style.boxShadow == "none") {
        head.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.12)"
      } else if (scrollTop < 10) {
        head.style.boxShadow = "none"
      }
    }

    if (nstc) {
      let azone = document.getElementById(`bw_azone_${id}`)
      if (azone != null) {
        if (scrollTop > (scrollHeight - clientHeight - 50)) {
          console.log("activate", azone.style.opacity)
          if (azone.style.opacity == "0") {
            azone.style.display = "block"
            setTimeout(() => {
              azone.style.opacity = "1"
            }, 100)
          }
        } else {
          if (azone.style.opacity == "1") {
            azone.style.opacity = "0"
            setTimeout(() => {
              azone.style.display = "none"
            }, 100)
          }
        }
      }
    }
  }
  
  useEffect(() => {
    const html = document.getElementsByTagName("html")
      
    if (visible) {
      open()

      if (width > 768 && closable) {
        document.addEventListener('mouseup', onMouseUp)
        document.addEventListener('keydown', onKeydown)
      }

      let page = document.getElementById(`bw_page_${id}`)
      console.log(page)
      if (page != null) {
        page.addEventListener('scroll', onScroll)
        page.scrollIntoView()
      }

      console.log(id)
      html[0].style.overflowY = "hidden"
    } else {
      if (width > 768 && closable) {
        document.removeEventListener('mouseup', onMouseUp)
        document.removeEventListener('keydown', onKeydown)
      }

      let page = document.getElementById(`bw_page_${id}`)
      if (page != null) {
        page.removeEventListener('scroll', onScroll)
      }

      html[0].style.overflowY = "scroll"

      close()
    }
  }, [visible])

  return (
    <>
      <div id={id} className={styles.backdrop + (theme == 'dark' ? " " + styles.dark : " " + styles.light) + (postVisible ? "" : " " + styles.hidden) + (preVisible ? " " + styles.showed : "") + (mainVisible ? " " + styles.openned : "")}>
        <div className={styles.wrapper}>
          <div className={styles.window} id={`booking_window_${id}`}>
            <div className={styles.frame} style={{opacity: isDataLoading ? "0" : "1"}}>
              <h2 id={`bw_head_${id}`}><span style={{width: selectedStep == 0 ? "0px" : "30px"}}><i className="far fa-chevron-left"></i></span> {title}</h2>

              {closable ? <div className={styles.closer} onClick={() => close()}><i className="far fa-xmark"></i></div> : <></>}

              <div className={styles.page} id={`bw_page_${id}`}>
                {content}
              </div>

              {activeZone ? <div className={styles.action_zone} id={`bw_azone_${id}`} style={nstc ? {opacity: "0", display: "none"} : {}}>
                <button onClick={() => {
                  if (id == "hello") {
                    if (loyaltyStore.isAccepted) {
                      onClose()
                    } else {
                      message.error("Примите правила сервиса...")
                    }
                  }
                }}><span></span><b>Продолжить</b></button>
              </div> : <></>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(XPageModal)