import React from "react"


const LoyaltyBlocked = React.lazy(() => import("../../pages/Loyalty/LoyaltyBlocked"))
const LoyaltyBasic = React.lazy(() => import("../../pages/Loyalty/LoyaltyBasic"))
const LoyaltyAuth = React.lazy(() => import("../../pages/Loyalty/LoyaltyAuth"))
const LoyaltyStore = React.lazy(() => import("../../pages/Loyalty/LoyaltyStore"))
const Privacy = React.lazy(() => import("../../pages/Privacy"))
const PublicOferta = React.lazy(() => import("../../pages/PublicOferta"))
const PrivacyRules = React.lazy(() => import("../../pages/PrivacyRules"))
const NotFoundPage = React.lazy(() => import("../../pages/NotFoundPage"))

export interface IRoute {
  path: string
  component: React.ReactNode
  exact?: boolean
}

export enum RouteNames {
  P404 = '*',
  HOME = '/',
  BBSHOW = '/barbershow',
  BBSHOW2 = '/barbershow2',
  BLOG = '/blog',
  BLOG_ID = '/blog/:page',
  BLOG_ID_POST = '/blog/:page/:post',
  BLOG2 = '/blog2',
  BLOG3 = '/blog3',
  BOOK = '/book',

  LOYALTY_REF = '/r/:name',
  LOYALTY = '/loyalty',
  LOYALTY_HIDDEN = '/loyalty_hidden',
  LOYALTY_AUTH = '/loyalty/auth',
  LOYALTY_STORE = '/loyalty/store',
  LOYALTY_STORE_PROD = '/loyalty/store/:id',
  LOYALTY_STORE_PROD2 = '/loyalty/store/2/:id',
  LOYALTY_ACCOUNT = '/loyalty/account',
  LOYALTY_ACCOUNT_PAGE = '/loyalty/account/:page',
  LOYALTY_ORDERS = '/loyalty/orders',

  LOYALTY_S = '/loyaltys',
  LOYALTY_S_HIDDEN = '/loyalty_hiddens',
  LOYALTY_S_BLOCKED = '/blocked',
  LOYALTY_S_AUTH = '/auth',
  LOYALTY_S_STORE = '/store',
  LOYALTY_S_STORE_PROD = '/store/:id',
  LOYALTY_S_STORE_LINK = '/store/:link',
  LOYALTY_S_STORE_PROD2 = '/store/2/:id',
  LOYALTY_S_ACCOUNT = '/account',
  LOYALTY_S_ACCOUNT_PAGE = '/account/:page',
  LOYALTY_S_ORDERS = '/orders',
  
  VACANCIES = '/vacancies',
  VACANCIES2 = '/vacancies2',
  FRANCHISE = '/franchise',
  BRANCHES = '/branches',
  BRANCHE_PAGE = '/branches/:name',
  BRANCHE_PAGE_RG = '/branches/rg/:rid',
  BRANCHE_RG_PAGE = '/branches/:rid/:id',

  B_BBSH = '/barbershop',
  B_BBSHS = '/barbershops',
  B_BBSH_P = '/barbershop/:name',
  B_BBSH_P1 = '/barbershop/rg/:rid',
  B_BBSH_P2 = '/barbershop/:rid/:id',

  B_BRANCHES = '/b',
  B_BRANCHE_PAGE = '/b/:name',
  B_BRANCHE_PAGE_RG = '/b/rg/:rid',
  B_BRANCHE_RG_PAGE = '/b/:rid/:id',

  BEBARBER = '/bebarber',
  PRIVACY = '/privacy',
  PRIVACY_OFERTA = '/privacy/oferta',
  PRIVACY_RULES = '/privacy/rules',

  ADMIN = '/admin',
  ADMIN_AUTH = '/admin/signin',

  ADMIN_HOME = '/admin/home',
  ADMIN_BRAN = '/admin/branches',
  ADMIN_BRAN_GO = '/admin/branches_go/:go_cid',
  ADMIN_BRAN_C = '/admin/branches/:cid',
  ADMIN_BRAN_C_R = '/admin/branches/:cid/:rid',
  ADMIN_BRAN_C_R_B = '/admin/branches/:cid/:rid/:bid',
  ADMIN_BLOG = '/admin/blog',
  ADMIN_BLOG_POST = '/admin/blog/:id',
  ADMIN_FRAN = '/admin/franchise',
  ADMIN_USERS = '/admin/users',
  ADMIN_USERS_O = '/admin/users/:id',
  ADMIN_CRM = '/admin/crm',
  ADMIN_ACCOUNT = '/admin/account',

  ADMIN_LOYALTY = '/admin/loyalty',
  ADMIN_LOYALTY_STATS = '/admin/loyalty/stats',
  ADMIN_LOYALTY_CLIENTS = '/admin/loyalty/clients',
  ADMIN_LOYALTY_CLIENTS_ID = '/admin/loyalty/clients/:id',
  ADMIN_LOYALTY_STORE = '/admin/loyalty/store',
  ADMIN_LOYALTY_STORE_ID = '/admin/loyalty/store/:id',
  ADMIN_LOYALTY_ORDERS = '/admin/loyalty/orders',
  ADMIN_LOYALTY_ORDERS_ID = '/admin/loyalty/orders/:id',
  ADMIN_LOYALTY_STORIES = '/admin/loyalty/stories',
  ADMIN_LOYALTY_MAXMA = '/admin/loyalty/maxma',

  ADMIN_EVENTS = '/admin/events',
  ADMIN_EVENTS_O = '/admin/events/:id',
  ADMIN_EVENTS_O_SCAN = '/admin/events/:id/scanner',
  ADMIN_EVENTS_SCANNER = '/admin/events_scanner',
  ADMIN_CONTENT = '/admin/content',
  ADMIN_SETT = '/admin/settings'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.P404, exact: true, component: <NotFoundPage /> },
  { path: RouteNames.HOME, exact: true, component: <LoyaltyBasic /> },

  { path: RouteNames.LOYALTY_REF, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.LOYALTY, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.LOYALTY_HIDDEN, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.LOYALTY_AUTH, exact: true, component: <LoyaltyAuth /> },
  { path: RouteNames.LOYALTY_ACCOUNT, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_ACCOUNT_PAGE, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_STORE, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_STORE_PROD, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_ORDERS, exact: true, component: <LoyaltyStore /> },

  { path: RouteNames.LOYALTY_S, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.LOYALTY_S_HIDDEN, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.LOYALTY_S_BLOCKED, exact: true, component: <LoyaltyBlocked /> },
  { path: RouteNames.LOYALTY_S_AUTH, exact: true, component: <LoyaltyAuth /> },
  { path: RouteNames.LOYALTY_S_ACCOUNT, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_S_ACCOUNT_PAGE, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_S_STORE, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_S_STORE_PROD, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_S_ORDERS, exact: true, component: <LoyaltyStore /> },

  { path: RouteNames.PRIVACY, exact: true, component: <Privacy /> },
  { path: RouteNames.PRIVACY_OFERTA, exact: true, component: <PublicOferta /> },
  { path: RouteNames.PRIVACY_RULES, exact: true, component: <PrivacyRules /> },
  
  { path: RouteNames.ADMIN, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_HOME, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_BRAN, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_BRAN_GO, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_BRAN_C, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_BRAN_C_R, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_BRAN_C_R_B, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_BLOG, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_BLOG_POST, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_FRAN, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_CRM, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_USERS, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_USERS_O, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_ACCOUNT, exact: true, component: <LoyaltyBasic /> },

  { path: RouteNames.ADMIN_LOYALTY, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_LOYALTY_STATS, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_LOYALTY_CLIENTS, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_LOYALTY_CLIENTS_ID, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_LOYALTY_STORE, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_LOYALTY_STORE_ID, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_LOYALTY_ORDERS, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_LOYALTY_ORDERS_ID, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_LOYALTY_STORIES, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_LOYALTY_MAXMA, exact: true, component: <LoyaltyBasic /> },

  { path: RouteNames.ADMIN_EVENTS, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_EVENTS_O, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_EVENTS_O_SCAN, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_EVENTS_SCANNER, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_CONTENT, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_SETT, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.ADMIN_AUTH, exact: true, component: <LoyaltyBasic /> }
]

export const twRoutes: IRoute[] = []
